* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

body {
  font-size: 14px;
  color: #333333; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto; }

[role="button"] {
  cursor: pointer; }

.pull-right, body #head #nav a.btn, body #head #nav .nav {
  float: right !important; }

.pull-left, body #head #nav a.logo {
  float: left !important; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; }
  .container:before, .container:after {
    content: " ";
    display: table; }
  .container:after {
    clear: both; }
  @media (min-width: 768px) {
    .container {
      width: 750px; } }
  @media (min-width: 992px) {
    .container {
      width: 970px; } }
  @media (min-width: 1200px) {
    .container {
      width: 1170px; } }

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; }
  .container-fluid:before, .container-fluid:after {
    content: " ";
    display: table; }
  .container-fluid:after {
    clear: both; }

.row {
  margin-left: -15px;
  margin-right: -15px; }
  .row:before, .row:after {
    content: " ";
    display: table; }
  .row:after {
    clear: both; }
  .row::after, .row::before {
    box-sizing: border-box; }

body #head .text, body #head .text, body .title {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px; }

@media (min-width: 768px) {
  body #head .text {
    float: left; }
  body #head .text {
    width: 66.66667%; }
  body #head .text {
    margin-left: 16.66667%; } }

@media (min-width: 992px) {
  body #head .text, body .title {
    float: left; }
  body #head .text {
    width: 33.33333%; }
  body .title {
    width: 100%; }
  body #head .text {
    margin-left: 33.33333%; } }

html, body {
  overflow-x: hidden; }

body {
  margin: 0px;
  padding: 0px;
  background-color: white;
  color: #5a5a5a;
  line-height: 25px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 400;
  min-height: 100vh;
  overflow: hidden;
  /* START head options */
  /* END head options */ }
  body .clearfix {
    clear: both; }
  body .text-right {
    text-align: right; }
  body .img-responsive {
    width: 100%;
    height: auto; }
  body .container-fluid {
    padding-left: 0px;
    padding-right: 0px; }
  body .blank {
    width: 100%;
    height: 30px; }
  body strong,
  body b,
  body .strong {
    font-weight: 600; }
  body .p-t-60 {
    padding-top: 60px; }
  body ul.default {
    margin: 0px;
    padding: 0px;
    list-style: none;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-margin-start: 0;
    -webkit-margin-end: 0;
    -webkit-padding-start: 0; }
    body ul.default li {
      list-style: none; }
  body .title {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px; }
    body .title h2 {
      font-size: 30px; }
  body a {
    cursor: pointer; }
  body a.btn, body button.btn {
    border: none;
    background-color: #999999;
    font-weight: bold;
    padding: 17px 47px;
    border-radius: 60px;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color .5s; }
    body a.btn.green, body button.btn.green {
      background-color: #006E2D; }
      body a.btn.green:hover, body button.btn.green:hover {
        background-color: #003b18; }
    body a.btn.gold, body button.btn.gold {
      background-color: #CD9B3C; }
      body a.btn.gold:hover, body button.btn.gold:hover {
        background-color: #aa7f2c; }
    body a.btn.white, body button.btn.white {
      background-color: white; }
      body a.btn.white:hover, body button.btn.white:hover {
        background-color: #e6e6e6; }
    body a.btn.green-text, body button.btn.green-text {
      color: #006E2D !important; }
  body .content {
    width: 100%; }
    body .content.active {
      -webkit-transform: translate3d(-280px, 0, 0);
      transform: translate3d(-280px, 0, 0); }
  body a.mobile-btn {
    display: none;
    text-decoration: none; }
  body h1, body h2, body h3 {
    color: #006E2D;
    font-size: 50px;
    line-height: 50px;
    font-weight: bold;
    margin-bottom: 0px; }
    body h1 small, body h2 small, body h3 small {
      display: block;
      color: black;
      font-size: 29px;
      font-weight: 100; }
  body h3 {
    margin-top: 0px;
    color: black;
    font-size: 28px;
    display: block;
    font-weight: normal; }
  body h4 {
    color: #006E2D;
    margin: 0px;
    margin-top: 10px; }
  body em {
    color: rgba(0, 0, 0, 0.5); }
  body #head {
    width: 100%;
    overflow: hidden;
    position: relative; }
    @media screen and (max-width: 1086px) {
      body #head {
        background-size: cover; } }
    body #head.subpage {
      background-image: url(../images/headerSubpageBg.jpg);
      background-color: #15100d;
      background-size: auto 100%;
      background-position: right center;
      background-repeat: no-repeat;
      padding-bottom: 20px; }
      body #head.subpage .header h1 {
        margin-top: 220px;
        color: white; }
    body #head #nav {
      position: absolute;
      width: 100%;
      z-index: 999; }
      body #head #nav .col-md-12 {
        padding-left: 30px;
        padding-right: 30px; }
      body #head #nav a.logo {
        padding-top: 10px; }
      body #head #nav a.btn {
        background-color: #006E2D;
        margin-top: 20px;
        margin-left: 40px; }
        body #head #nav a.btn:hover {
          background-color: #003b18; }
      @media screen and (max-width: 1350px) {
        body #head #nav a.logo {
          padding-top: 20px; }
          body #head #nav a.logo img {
            width: 150px; }
        body #head #nav a.btn {
          padding: 10px;
          font-size: 14px;
          margin-top: 30px !important;
          margin-left: 10px !important; } }
      body #head #nav .nav {
        list-style: none;
        margin: 0px;
        padding-top: 40px;
        padding-bottom: 20px; }
        body #head #nav .nav li {
          display: inline-block;
          font-size: 17px;
          padding-left: 25px; }
          body #head #nav .nav li a {
            color: white;
            text-decoration: none; }
            body #head #nav .nav li a.active {
              font-weight: bold;
              border-bottom: 2px solid #006E2D;
              padding-bottom: 5px; }
            body #head #nav .nav li a:hover {
              border-bottom: 2px solid #006E2D;
              padding-bottom: 5px; }
          body #head #nav .nav li.social {
            padding-left: 10px;
            font-size: 20px;
            position: relative; }
      @media screen and (max-width: 1195px) {
        body #head #nav a.mobile-btn {
          display: block;
          color: white;
          position: absolute;
          right: 30px;
          font-size: 30px;
          margin-top: 35px; }
        body #head #nav a.btn {
          padding: 10px;
          font-size: 14px;
          margin-top: 30px !important;
          margin-left: 0px !important;
          margin-right: 60px !important; }
        body #head #nav .nav {
          float: none !important;
          width: 100%;
          left: 0px;
          background-color: black;
          margin-left: 0px !important;
          clear: both !important;
          -webkit-padding-start: 0px;
          display: none; }
          body #head #nav .nav.active {
            display: block; }
          body #head #nav .nav li {
            display: block !important;
            line-height: 40px; }
            body #head #nav .nav li a i {
              display: none; }
            body #head #nav .nav li a.active {
              border: none !important; } }
    body #head .text {
      text-align: center;
      color: white;
      padding-top: 160px;
      padding-bottom: 40px; }
      body #head .text p {
        font-size: 26px;
        line-height: 38px; }
        body #head .text p a {
          color: white;
          text-decoration: none;
          border-bottom: 2px dashed white;
          padding-bottom: 10px; }
  body .owl-head .item {
    padding-top: 200px;
    background-image: url(../images/sliderBg.jpg);
    padding-bottom: 100px; }
    body .owl-head .item .texts {
      display: block;
      width: 74%;
      border: 2px solid white;
      margin-left: auto;
      margin-right: auto;
      padding: 3%; }
      body .owl-head .item .texts big {
        font-size: 50px;
        font-weight: bold;
        color: white;
        line-height: 55px; }
      body .owl-head .item .texts img {
        width: auto;
        height: auto;
        min-width: auto;
        position: absolute;
        margin-top: -500px;
        right: 10%; }
    @media screen and (max-width: 1280px) {
      body .owl-head .item .texts img {
        right: -5%; } }
    @media screen and (max-width: 960px) {
      body .owl-head .item .texts {
        text-align: center;
        padding-bottom: 40px; }
        body .owl-head .item .texts a {
          margin-bottom: 20px;
          display: block; }
        body .owl-head .item .texts img {
          display: none !important; } }
  body .owl-head .owl-dots {
    position: absolute;
    right: 20px;
    top: 200px; }
    body .owl-head .owl-dots .owl-dot {
      display: block;
      width: 15px;
      height: 15px;
      background-color: #1e1816;
      border-radius: 15px;
      margin-bottom: 5px; }
      body .owl-head .owl-dots .owl-dot.active {
        background-color: #006E2D; }

.row.display-flex {
  display: flex;
  flex-wrap: wrap; }
  .row.display-flex::before {
    content: "";
    display: none; }

.row.display-flex > [class*='col-'] {
  display: flex;
  flex-direction: column;
  float: none; }

.no-flexbox .row.display-flex {
  display: table;
  content: " ";
  margin-right: -15px;
  margin-left: -15px; }
  .no-flexbox .row.display-flex::before, .no-flexbox .row.display-flex::after {
    display: table;
    content: " "; }
  .no-flexbox .row.display-flex::after {
    clear: both; }

.no-flexbox .row.display-flex > [class*='col-'] {
  position: relative;
  min-height: 1px;
  display: block;
  float: left; }
