@import "_bootstrap-min";

html,body{
	overflow-x: hidden;
}
body{
	margin: 0px;
	padding: 0px;
	background-color:white;
	color: #5a5a5a;
	line-height: 25px;
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
	font-weight: 400;
	min-height: 100vh;
	overflow: hidden;
	%noPadding{
		padding: 0px;
	}
	.clearfix{
		clear: both;
	}
	.text-right{
		text-align: right;
	}
	.img-responsive{
		width: 100%;
		height: auto;
	}
	.container-fluid{
		padding-left: 0px;
		padding-right: 0px;
	}
	.blank{
		width: 100%;
		height: 30px;
	}
	strong,
	b,
	.strong{
		font-weight: 600;
	}
	.p-t-60{
		padding-top: 60px;
	}
	ul.default{
		margin: 0px;
		padding: 0px;
		list-style: none;
		-webkit-margin-before: 0;
		-webkit-margin-after: 0;
		-webkit-margin-start: 0;
		-webkit-margin-end: 0;
		-webkit-padding-start: 0;
		li{
			list-style: none;
		}
	}
	.title{
		@extend %col-md-12;
		text-align: center;
		padding-top: 20px;
		padding-bottom: 20px;
		h2{
			font-size: 30px;
		}
	}
	a{
		cursor: pointer;
	}
	a.btn,button.btn{
		border: none;
		background-color: #999999;
		font-weight: bold;
		padding: 17px 47px;
		border-radius: 60px;
		color: white;
		text-decoration: none;
		text-transform: uppercase;
		transition: background-color .5s;
		&.green{
			background-color: #006E2D;

			&:hover {
				background-color: darken(#006E2D, 10%);
			}
		}
		&.gold {
			background-color: #CD9B3C;

			&:hover {
				background-color: darken(#CD9B3C, 10%);
			}
		}
		&.white{
			background-color: white;

			&:hover {
				background-color: darken(#fff, 10%);
			}
		}
		&.green-text{
			color: #006E2D !important;
		}
	}
	.content{
		width: 100%;
		&.active{
			-webkit-transform: translate3d(-280px, 0, 0);
			transform: translate3d(-280px, 0, 0);
		}
	}
	a.mobile-btn{
		display: none;
		text-decoration: none;
	}
	h1,h2,h3{
		color: #006E2D;
		font-size: 50px;
		line-height: 50px;
		font-weight: bold;
		margin-bottom: 0px;
		small{
			display: block;
			color: black;
			font-size: 29px;
			font-weight: 100;
		}
	}
	h3{
		margin-top: 0px;
		color: black;
		font-size: 28px;
		display: block;
		font-weight: normal;
	}
	h4{
		color: #006E2D;
		margin: 0px;
		margin-top: 10px;
	}
	em{
		color: rgba(0,0,0,.5);
	}
	/* START head options */
	#head{
		width: 100%;
		overflow: hidden;
		position: relative;
		@media screen and (max-width:1086px) {
			background-size: cover;
		}
		&.subpage{
			background-image: url(../images/headerSubpageBg.jpg);
			background-color: #15100d;
			background-size: auto 100%;
			background-position: right center;
			background-repeat: no-repeat;
			padding-bottom: 20px;
			.header{
				h1{
					margin-top: 220px;
					color: white;
				}
			}
		}
		#nav{
			position: absolute;
			width: 100%;
			z-index: 999;
			.col-md-12{
				padding-left: 30px;
				padding-right: 30px;
			}
			a.logo{
				@extend .pull-left;
				padding-top: 10px;
			}
			a.btn{
				@extend .pull-right;
				background-color: #006E2D;
				margin-top: 20px;
				margin-left: 40px;

				&:hover {
					background-color: darken(#006E2D, 10%);
				}
			}
			@media screen and (max-width:1350px) {
				a.logo{
					padding-top: 20px;
					img{
						width: 150px;
					}
				}
				a.btn{
					padding: 10px;
					font-size: 14px;
					margin-top: 30px !important;
					margin-left: 10px !important;
				}
			}
			.nav{
				@extend .pull-right;
				list-style: none;
				margin: 0px;
				padding-top: 40px;
				padding-bottom: 20px;
				li{
					display: inline-block;
					font-size: 17px;
					padding-left: 25px;
					a{
						color: white;
						text-decoration: none;
						&.active{
							font-weight: bold;
							border-bottom: 2px solid #006E2D;
							padding-bottom: 5px;
						}
						&:hover{
							border-bottom: 2px solid #006E2D;
							padding-bottom: 5px;
						}
					}
					&.social{
						padding-left: 10px;
						font-size: 20px;
						position: relative;
					}
				}
			}
			@media screen and (max-width:1195px) {
				a.mobile-btn{
					display: block;
					color: white;
					position: absolute;
					right: 30px;
					font-size: 30px;
					margin-top: 35px;
				}
				a.btn{
					padding: 10px;
					font-size: 14px;
					margin-top: 30px !important;
					margin-left: 0px !important;
					margin-right: 60px !important;
				}
				.nav{
					float: none !important;
					width: 100%;
					left: 0px;
					background-color: black;
					margin-left: 0px !important;
					clear: both !important;
					-webkit-padding-start: 0px;
					display: none;
					&.active{
						display: block;
					}
					li{
						display: block !important;
						line-height: 40px;
						a{
							i{
								display: none;
							}
							&.active{
								border: none !important;
							}
						}
					}
				}
			}

		}
		.text{
			@extend %col-md-4;
			@extend %col-md-offset-4;
			@extend %col-sm-8;
			@extend %col-sm-offset-2;
			text-align: center;
			color: white;
			padding-top: 160px;
			padding-bottom: 40px;
			p{
				font-size: 26px;
				line-height: 38px;
				a{
					color: white;
					text-decoration: none;
					border-bottom: 2px dashed white;
					padding-bottom: 10px;
				}
			}
		}
	}
	.owl-head{
		.item{
			padding-top: 200px;
			background-image: url(../images/sliderBg.jpg);
			padding-bottom: 100px;
			.texts{
				display: block;
				width: 74%;
				border: 2px solid white;
				margin-left: auto;
				margin-right: auto;
				padding: 3%;
				big{
					font-size:50px;
					font-weight: bold;
					color: white;
					line-height:55px;
				}
				img{
					width: auto;
					height: auto;
					min-width: auto;
					position: absolute;
					margin-top: -500px;
					right: 10%;
				}
			}
			@media screen and (max-width:1280px) {
				.texts{
					img{
						right: -5%;
					}
				}
			}
			@media screen and (max-width:960px) {
				.texts{
					text-align: center;
					padding-bottom: 40px;
					a{
						margin-bottom: 20px;
						display: block;
					}
					img{
						display: none !important;
					}
				}
			}
		}
		.owl-dots{
			position: absolute;
			right: 20px;
			top: 200px;
			.owl-dot{
				display: block;
				width: 15px;
				height: 15px;
				background-color: #1e1816;
				border-radius: 15px;
				margin-bottom: 5px;
				&.active{
					background-color: #006E2D;
				}
			}
		}
	}

	/* END head options */


}

.row.display-flex {
	display: flex;
	flex-wrap: wrap;
	&::before{
		content: "";
		display: none;
	}
}
.row.display-flex > [class*='col-'] {
	display: flex;
	flex-direction: column;
	float: none;
}
.no-flexbox{
	.row.display-flex {
		display: table;
		content: " ";
		margin-right: -15px;
		margin-left: -15px;
		&::before,&::after{
			display: table;
			content: " ";
		}
		&::after{
			clear: both
		}
	}
	.row.display-flex > [class*='col-'] {
		position: relative;
		min-height: 1px;
		display: block;
		float: left;
	}
}
