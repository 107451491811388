@import "bootstrap/variables";

@import "bootstrap/mixins";
//@import "bootstrap/mixins/grid";
//@import "bootstrap/mixins/clearfix";

@import "bootstrap/scaffolding";

.pull-right {
	float: right !important;
}
.pull-left {
	float: left !important;
}

.container {
	@include container-fixed;

	@media (min-width: $screen-sm-min) {
		width: $container-sm;
	}
	@media (min-width: $screen-md-min) {
		width: $container-md;
	}
	@media (min-width: $screen-lg-min) {
		width: $container-lg;
	}
}

.container-fluid {
	@include container-fixed;
}

.row {
	@include make-row;
	&::after,&::before{
		box-sizing: border-box
	}
}


@mixin make-grid-columns($i: 1, $list: "%col-xs-#{$i}, %col-sm-#{$i}, %col-md-#{$i}, %col-lg-#{$i}") {
	@for $i from (1 + 1) through $grid-columns {
		$list: "#{$list}, %col-xs-#{$i}, %col-sm-#{$i}, %col-md-#{$i}, %col-lg-#{$i}";
	}
	#{$list} {
		position: relative;
		min-height: 1px;
		padding-left:  ceil(($grid-gutter-width / 2));
		padding-right: floor(($grid-gutter-width / 2));
	}
}

@mixin float-grid-columns($class, $i: 1, $list: "%col-#{$class}-#{$i}") {
	@for $i from (1 + 1) through $grid-columns {
		$list: "#{$list}, %col-#{$class}-#{$i}";
	}
	#{$list} {
		float: left;
	}
}

@mixin calc-grid-column($index, $class, $type) {
	@if ($type == width) and ($index > 0) {
		%col-#{$class}-#{$index} {
			width: percentage(($index / $grid-columns));
		}
	}
	@if ($type == push) and ($index > 0) {
		%col-#{$class}-push-#{$index} {
			left: percentage(($index / $grid-columns));
		}
	}
	@if ($type == push) and ($index == 0) {
		%col-#{$class}-push-0 {
			left: auto;
		}
	}
	@if ($type == pull) and ($index > 0) {
		%col-#{$class}-pull-#{$index} {
			right: percentage(($index / $grid-columns));
		}
	}
	@if ($type == pull) and ($index == 0) {
		%col-#{$class}-pull-0 {
			right: auto;
		}
	}
	@if ($type == offset) {
		%col-#{$class}-offset-#{$index} {
			margin-left: percentage(($index / $grid-columns));
		}
	}
}

@mixin loop-grid-columns($columns, $class, $type) {
	@for $i from 0 through $columns {
		@include calc-grid-column($i, $class, $type);
	}
}

@mixin make-grid($class) {
	@include float-grid-columns($class);
	@include loop-grid-columns($grid-columns, $class, width);
	@include loop-grid-columns($grid-columns, $class, pull);
	@include loop-grid-columns($grid-columns, $class, push);
	@include loop-grid-columns($grid-columns, $class, offset);
}

@include make-grid-columns;
@include make-grid(xs);

@media (min-width: $screen-sm-min) {
	@include make-grid(sm);
}

@media (min-width: $screen-md-min) {
	@include make-grid(md);
}

@media (min-width: $screen-lg-min) {
	@include make-grid(lg);
}

%col-xs-5ths,
%col-sm-5ths,
%col-md-5ths,
%col-lg-5ths {
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}

%col-xs-5ths {
	width: 20%;
	float: left;
}

@media (min-width: 768px) {
	%col-sm-5ths {
		width: 20%;
		float: left;
	}
}

@media (min-width: 992px) {
	%col-md-5ths {
		width: 20%;
		float: left;
	}
}

@media (min-width: 1200px) {
	%col-lg-5ths {
		width: 20%;
		float: left;
	}
}
